@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";

@layer base {
  :root {
    --color-primary-main: #E7521F;
    --color-secondary-main: #7F7F7F;
    --color-secondary-light: #D0CECE;
    --color-secondary-dark: #404040;
    --color-secondary-contrast: #FFF;
    --color-success: #3DB037;
    --color-success-contrast: #FFF;
    --color-error: #E36E5D;
    --color-error-contrast: #FFF;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  white-space: 'pre-line';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
