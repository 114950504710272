.image-gallery-slides {
 display: flex ;
}

.image-gallery-image {
  border-radius:  0.75rem 0.75rem 0 0 ;
}
.image-gallery-slide-smallScreen {

  height: calc( 100vh * 14/24 - 100px) ; 
 
}

.image-gallery-slide-fullScreen {
  height: 100%; 
  width: 100%;
}




.image-gallery-thumbnail.active , .image-gallery-thumbnail:hover {
  /* won't work without "!important"
   https://github.com/xiaolin/react-image-gallery/issues/761  */
  border: var(--color-primary) solid 4px !important;
}

.image-gallery-svg {
    /* won't work without "!important" */
  width: 30px !important;
}
.image-gallery-icon:hover {
    /* won't work without "!important" */
  color: var(--color-primary) !important;
}

